/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
import React from 'react'
import Popup from 'reactjs-popup'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import './Popup.css'

// Start with popup showing
let cancelledPopup = 0
// Check if email has been submitted from previous visits
let submittedPopup = window.localStorage.getItem('submittedPopup')
// If email has never been submitted, show popup
if (submittedPopup === null) {
  window.localStorage.setItem('submittedPopup', '0')
}
// If this is the first visit set submittedPopup to 0
submittedPopup = window.localStorage.getItem('submittedPopup')

// Get this from the form's URL. Must add "/post" after "/subscribe"
const url =
  'https://cmawallets.us20.list-manage.com/subscribe/post?u=9856594bb04906398cf582727&id=a40c7a0b35'

const emailPopup = () => {
  // Check if popup has been cancelled this session, or if email has ever been submitted
  if (cancelledPopup === 0 || submittedPopup === '0') {
    return (
      <>
        <Popup
          defaultOpen
          style={{ width: '350px' }}
          modal
          closeOnDocumentClick
          closeOnEscape
          contentStyle={{ width: '340px' }}>
          {close => (
            <div className='modal'>
              <div className='header'> Newsletter, offers & promotions </div>
              <div className='content center'>
                {
                  'If you provide us with your email address we will contact you with discounts, and updates regarding the stores you can find our product.'
                }
              </div>
              <div
                onKeyPress={() => null}
                tabIndex={0}
                className='ma2'
                role='button'
                onClick={() => {
                  cancelledPopup += 1
                  window.localStorage.setItem('submittedPopup', '1')
                }}>
                <MailchimpSubscribe url={url} />
              </div>
              <div className='actions flex justify-center'>
                <button
                  type='button'
                  className='button ma2'
                  onClick={() => {
                    close()
                    cancelledPopup += 1
                  }}>
                  {'Close'}
                </button>
              </div>
            </div>
          )}
        </Popup>
        {/* <Popup
          defaultOpen
          style={{ width: '350px' }}
          modal
          closeOnDocumentClick
          closeOnEscape
          contentStyle={{ width: '340px' }}>
          {close => (
            <div className='modal'>
              <div className='header'>A Message from John</div>
              <div className='content center'>
                {
                  <>
                    &nbsp;Regarding what is going on in the world with the
                    Coronavirus, many of us are starting to feel more and more
                    uneasy. Because of this, I am reducing the cost of our
                    holster by 20%.
                    <br />
                    <br />
                    &nbsp;On checkout, type in <strong>CV20</strong> and 20% 
                    will be taken off of the price of the holster.
                    <br />
                    <br />
                    &nbsp;Remember that there is still no cost for shipping in
                    the continental U.S. Please watch out for yourself, your
                    loved ones and others in the community during this uniquely
                    trying time.
                  </>
                }
              </div>
              <div className='actions flex justify-center'>
                <button
                  type='button'
                  className='button ma2'
                  onClick={() => {
                    close()
                    cancelledPopup += 1
                  }}>
                  {'Close'}
                </button>
              </div>
            </div>
          )}
        </Popup> */}
      </>
    )
  } else return null
}

export default emailPopup
